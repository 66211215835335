import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { storeAsync } from '../../app-module';

const ApplicationsList = () => import('@/modules/applications/pages/ApplicationsList.vue');
const ApplicationsForm = () => import('@/modules/applications/pages/ApplicationsForm.vue');
const ApplicationView = () => import('@/modules/applications/pages/ApplicationView.vue');
const ApplicationEdit = () => import('@/modules/applications/pages/ApplicationEdit.vue');
const ApplicationsConfirmation = () => import('@/modules/applications/pages/ApplicationConfirmation.vue');
const ApplicationPaymentNotify = () => import('@/modules/applications/pages/ApplicationPaymentNotify.vue');
const ApplicationDocumentsEdit = () => import('@/modules/applications/components/view-application/documents/ApplicationDocumentsEdit.vue');
const ApplicationsAssignedList = () => import('@/modules/applications/pages/ApplicationsAssignedList.vue');
const ApplicationsInWorkList = () => import("@/modules/applications/pages/ApplicationsInWorkList.vue");
const ApplicationsCompletedList = () => import("@/modules/applications/pages/ApplicationsCompletedList.vue");
const NewApplicationsList = () => import("@/modules/applications/pages/NewApplicationsList.vue");
const ApplicationsPaymentOrder = () => import('@/modules/applications/pages/ApplicationsPaymentOrder.vue');

const getApplicationsModule = () => import('@/modules/applications/applications-store');
const importApplicationsStore = () =>
    getApplicationsModule().then(async (storeModule) => {
        if (!storeAsync().hasModule('applications')) await storeAsync().registerModule('applications', storeModule.default);
        else return;
    });

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'applications',
                path: '/applications',
                component: ApplicationsList,
                meta: {
                    auth: true,
                    permission: Permissions.values.applicationsView
                },
                beforeEnter: (to, from, next) => {
                    importApplicationsStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'applicationNew',
                path: '/application/new/:appId?',
                props: true,
                component: ApplicationsForm,
                meta: {
                    auth: true,
                    permission: Permissions.values.applicationCreate
                },
                beforeEnter: (to, from, next) => {
                    importApplicationsStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'applicationEdit',
                path: '/application/:id/edit',
                component: ApplicationEdit,
                meta: {
                    auth: true,
                    permission: Permissions.values.applicationsView
                },
                props: true,
                beforeEnter: (to, from, next) => {
                    importApplicationsStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'applicationView',
                path: '/application/:id/:tab?',
                component: ApplicationView,
                beforeEnter: (to, from, next) => {
                    importApplicationsStore().then(() => {
                        next();
                    });
                },
                meta: {
                    auth: true,
                    permission: Permissions.values.applicationsView
                },
                props: true,
            },
            {
                name: 'applicationsPaymentOrder',
                path: '/application-payment/:applicationId/:opPaymentId',
                component: ApplicationsPaymentOrder,
                meta: {
                    auth: true,
                },
                props: true,
                beforeEnter: (to, from, next) => {
                    importApplicationsStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'applicationConfirmation',
                path: '/application-confirmation/:id',
                component: ApplicationsConfirmation,
                meta: {
                    auth: true,
                },
                props: true,
                beforeEnter: (to, from, next) => {
                    importApplicationsStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'applicationPaymentNotify',
                path: '/application-notify/:id',
                component: ApplicationPaymentNotify,
                meta: {
                    auth: true,
                },
                props: true,
                beforeEnter: (to, from, next) => {
                    importApplicationsStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'applicationsAssigned',
                path: '/applications-assigned',
                component: ApplicationsAssignedList,
                exact: true,
                meta: {
                    auth: true,
                    permission: Permissions.values.applicationsStaffOwnRead
                },
                beforeEnter: (to, from, next) => {
                    importApplicationsStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'applicationsInWork',
                path: '/applications-in-work',
                component: ApplicationsInWorkList,
                exact: true,
                meta: {
                    auth: true,
                    permission: Permissions.values.applicationsStaffOwnRead
                },
                beforeEnter: (to, from, next) => {
                    importApplicationsStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'applicationsCompleted',
                path: '/applications-completed',
                component: ApplicationsCompletedList,
                exact: true,
                meta: {
                    auth: true,
                    permission: Permissions.values.applicationsStaffOwnRead
                },
                beforeEnter: (to, from, next) => {
                    importApplicationsStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'newApplications',
                path: '/new-applications',
                component: NewApplicationsList,
                exact: true,
                meta: {
                    auth: true,
                    permission: Permissions.values.applicationsStaffOwnRead
                },
                beforeEnter: (to, from, next) => {
                    importApplicationsStore().then(() => {
                        next();
                    });
                },
            },
        ],
    },
];
